<template>
  <erp-box style="min-height: 216px" label="Foto / Responsável">
    <div class="bem-img-thumb">
      <div style="width: 164px; height: 124px" v-if="activeCamera || imageByCamera">
        <video v-show="!imageByCamera" id="player" width="164" height="124" autoplay></video>
        <canvas v-show="imageByCamera" id="snapshot" width="164" height="124"></canvas>
      </div>
      <a target="_blank" :href="arrematante.image ? arrematante.image.full.url : '#'"
         v-else-if="tmp || (arrematante.image && arrematante.image.thumb && arrematante.image.thumb.url)">
        <img id="frame" :src="tmp || arrematante.image.thumb.url">
      </a>
      <u-icon v-else name="image" />
    </div>
    <p class="small text-center font-thin"><i>Somente para controle interno</i></p>
    <div class="text-center m-b-xs">
      <label for="camera">
        <e-btn @click="$refs.camera.click()" icon="upload" icon-type="fa" class="m-r-sm" />
        <input @change="cameraListener($event)" type="file" accept="image/*" capture="camera" id="camera" ref="camera"
               v-show="false">
      </label>
      <e-btn @click="openCamera" icon="camera" icon-type="fa" class="m-r-sm" />
      <e-btn @click.prevent="deleteImage" :disable="!arrematante.image && !tmp && !imageByCamera && !activeCamera" icon="times" icon-type="fa" icon-color="negative" />
    </div>
  </erp-box>
</template>

<script>
import {ErpBox} from 'uloc-vue-plugin-erp'
import {fileToBase64} from '../../../../utils/fileToBase64';

export default {
  name: 'Foto',
  props: ['arrematante'],
  data () {
    return {
      changed: false,
      tmp: null,
      activeCamera: false,
      imageByCamera: false,
      type: null,
      delete: false
    }
  },
  components: {
    ErpBox
  },
  mounted () {
    this.$inputImage = document.getElementById('camera')
    // this.$inputImage.addEventListener('change', this.cameraListener)
  },
  beforeDestroy () {
    this.stopCamera()
    // this.$inputImage.removeEventListener('change', this.cameraListener)
  },
  methods: {
    cameraListener (e) {
      this.activeCamera = false
      this.imageByCamera = false
      let frame = document.getElementById('frame')
      this.$file = e.target.files[0];
      // Do something with the image file.
      this.tmp = URL.createObjectURL(this.$file)
      this.changed = true
      this.delete = false
      this.type = 'upload'
      window.setTimeout(() => {
        this.updateImage()
      }, 50)
      // frame.src = URL.createObjectURL(file);
    },
    openCamera () {
      let start = false
      if (!this.activeCamera) {
        start = true
        navigator.mediaDevices.getUserMedia({video: true}).then(this.handleSuccess)
      }
      this.activeCamera = true
      this.imageByCamera = false
      this.$nextTick(() => {
        this.$player = document.getElementById('player')
        this.$snapshotCanvas = document.getElementById('snapshot')
        let context = this.$snapshotCanvas.getContext('2d')
        // Draw the video frame to the canvas.
        context.drawImage(this.$player, 0, 0, this.$snapshotCanvas.width, this.$snapshotCanvas.height)
        if (!start) {
          // Stop all video streams.
          this.stopCamera()
          this.imageByCamera = true
          this.activeCamera = false
          this.changed = true
          this.delete = false
          this.type = 'camera'
          window.setTimeout(() => {
            this.updateImage()
          }, 50)
        }
      })
    },
    handleSuccess (stream) {
      // Attach the video stream to the video element and autoplay.
      this.$player.srcObject = stream
      this.$videoTracks = stream.getVideoTracks();
    },
    stopCamera () {
      this.$videoTracks && this.$videoTracks.forEach(function (track) {
        track.stop()
      })
    },
    deleteImage () {
      this.stopCamera()
      this.changed = true
      this.tmp = null
      this.activeCamera = false
      this.imageByCamera = false
      this.delete = true
      this.$file && delete this.$file
      this.$emit('update', null)
    },
    getUploadedFileContent () {
      const file = this.$file
      return fileToBase64(file)
    },
    hasChanged () {
      return this.changed
    },
    toDelete () {
      return this.delete
    },
    getImage () {
      let response
      if (this.type === 'upload') {
        response = this.getUploadedFileContent()
      } else if (this.type === 'camera') {
        response = new Promise(resolve => {
          resolve(this.$snapshotCanvas.toDataURL())
        })
      }
      return response
    },
    updateImage () {
      this.getImage()
        .then(file => {
          this.$emit('update', file)
        })
        .catch(error => {
          alert('Falha ao processar a foto do arrematante, mas os demais dados foram processados com sucesso!')
        })
    }
  }
}
</script>

<style scoped>

</style>
